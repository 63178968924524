import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import VueLazyLoad from 'vue3-lazyload'
import { store } from './pinia'
import { DefaultApolloClient } from '@vue/apollo-composable'

import RouterPrefetch from './router/prefetch'
import { apolloClient } from '@/apolloClient'
//don't forget to uncomment this for google tag manager
// import VueGtag from 'vue-gtag'

export const Application = createApp(App)

Application.use(router)

// Google Tag Manager boilerplate

// const printTitle = (to) => {
//   if (to.meta && to.meta.title) {
//     return to.meta.title
//   }
//   return to.name
// }

// Application.use(
//   VueGtag,
//   {
//     pageTrackerTemplate(to) {
//       return {
//         page_title: printTitle(to),
//         page_path: to.path,
//       }
//     },
//     config: {
//       id: 'INSERT_ID_HERE',
//     },
//   },
//   router
// )

Application.use(RouterPrefetch, {
  componentName: 'QuickLink',
})
  .use(VueLazyLoad, {
    adapter: {
      loaded({
        // bindType,
        el,
        // naturalHeight,
        // naturalWidth,
        // $parent,
        // src,
        // loading,
        // error,
        // Init,
      }) {
        setTimeout(() => el.classList.add('loaded'), 100)
      },
    },
  })
  .provide(DefaultApolloClient, apolloClient)
  .use(store)
  .mount('#app')
router.beforeEach((to, from, next) => {
  next()
})
