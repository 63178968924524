import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import locoScroll from '../assets/js/LenisScroll'
import emitter from '@/scripts/emitter'

const { site } = window.__VUE_WORDPRESS__.state

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      emitter.once('pageTransition', () => {
        if (to.hash) {
          setTimeout(() => {
            locoScroll.scrollTo(to.hash, {
              immediate: false,
              lock: true,
              duration: 1,
            })
            emitter.emit('scrolled')
            resolve()
          }, 500)
        } else {
          if (savedPosition) {
            const scrollValue =
              savedPosition.top === 0 ? 'top' : savedPosition.top
            locoScroll.scrollTo(scrollValue, {
              immediate: false,
              lock: true,
              duration: 1,
            })
            emitter.emit('scrolled')
            resolve()
          } else {
            locoScroll.scrollTo('top', {
              immediate: false,
              duration: 1,
              lock: true,
            })
            emitter.emit('scrolled')
            resolve()
          }
        }
      })
    })
  },
})

router.afterEach((to) => {
  // set page title on route change
  window.document.title =
    `${to.meta.title} | ${site.name}` || `${site.name} | ${site.description}`
})
