<template>
  <div class="footer-nav-item-container">
    <nav>
      <ul
        v-if="menu"
        class="footer-nav-container">
        <template
          v-for="item in menu.menuItems.nodes"
          :key="item.id">
          <li :class="item.cssClasses.toString()">
            <quick-link
              :to="item.uri"
              :title="item.label">
              {{ item.label }}
            </quick-link>
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from '@/stores/main'

const store = useStore()
const props = defineProps(['name'])
const menu = ref(store.menu({ name: props.name }))
</script>

<style lang="scss">
.footer-nav-container {
  @apply flex flex-col;

  li {
    @apply mb-7;

    a {
      @apply cta-2;
    }
  }
}
</style>
